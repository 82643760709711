import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      location={location}
      title={siteTitle}
      invert
      className="bg-blue-500"
    >
      <Seo
        title="Psychic Reading Services Testimonials"
        description="Curious what others have to say about Psychic Reading Services? Read their testimonials to see how we’ve helped them connect with real psychics online."
      />
      <Container>
        <h1>Psychic Reading Services Testimonials</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="testimonial">
            <p>
              “This psychic review site narrowed down my options helping me find
              a legit psychic website for my needs.”
            </p>
            <p className="author">– Sam E, Philadelphia, PA </p>
          </div>
          <div className="testimonial">
            <p>
              “I like to try different psychics and this review pointed me to
              some of the best/most memorable readings so far. I really like
              PathForward.”
            </p>
            <p className="author">– Michelle T, Scranton, PA </p>
          </div>
          <div className="testimonial">
            <p>
              “10/10 would recommend this site to anyone looking to find the
              best psychic match for them.”
            </p>
            <p className="author">– Chris W, Charleston, SC</p>
          </div>
          <div className="testimonial">
            <p>
              “My friend convinced me to try getting a psychic reading, but I
              had no idea where to look. This site helped me find and compare
              services and I was able to easily choose the right service for
              me!”
            </p>
            <p className="author">– Julia M, Austin, TX </p>
          </div>
          <div className="testimonial">
            <p>
              “I needed guidance about my relationship and have talked to
              psychics before but didn’t feel like they connected with me at
              all. After reading the reviews on this site, I found a service
              that offers professional psychics that seem genuine.”
            </p>
            <p className="author">– Joe G, Hollywood Beach, FL</p>
          </div>
          <div className="testimonial">
            <p>
              “Love how easy this site makes it to compare top psychic services
              by specialty, type of reading, and more so you can find the best
              service quickly and know exactly what you’re getting.”
            </p>
            <p className="author">– Lauren V, New York, NY</p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
